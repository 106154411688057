
import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Audit from '@/models/Audit'
import ViewModel from '@/models/ViewModel'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

@Component({
  components: {
    Widget,
    SearchInput,
  },
})
export default class AuditHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public query: string[] = [];

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public fields: any = [
    {
      key: 'user_id',
      label: 'User',
      sortable: true,
      show: true,
    },
    {
      key: 'event',
      label: 'Action',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'auditable_id',
      label: 'Target',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Date',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'details',
      label: 'Details',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    user_fields: [
      {
        name: 'user',
        key: 'user_id',
      },
    ],
    custom_fields: [
      {
        name: 'action:created',
        value: 'event:created',
      },
      {
        name: 'action:updated',
        value: 'event:updated',
      },
      {
        name: 'action:deleted',
        value: 'event:deleted',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public audits(context: any) {
    this.loading = true
    return Audit.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public replaceUnderscore(value: any) {
    return value && value.length ? value.replace('_', ' ') : value
  }
}
