import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import AuditModule from '@/store/model/AuditModule'
import User from './User'
import PaginateOptions from './interface/PaginateOptions'

export default class Audit {
  public id: string = ''

  public user_id: string = ''

  public event: string = ''

  public old_values: any = null

  public new_values: any = null

  public created_at: string = ''

  public ip_address: string = ''

  public user_agent: string = ''

  public auditable_type: string = ''

  public auditable_id: string = ''

  public user: User = new User()

  public isType(type: string) {
    return type == this.type
  }

  private _type: string = ''

  public get type(): string {
    if (this._type == '') {
      const type_array = this.auditable_type.split('\\')
      this._type = type_array[type_array.length - 1]
    }
    return this._type
  }

  private _target?: string

  public get target() {
    if (!this._target) {
      const type_array = this.auditable_type.split('\\')
      const type: string = type_array[type_array.length - 1]

      this._target = `${type}: ${this.auditable_id}`
    }

    return this._target
  }

  public keyLength(ignore: string[] = []) {
    return this.keyFilter(ignore).length
  }

  public keyFilter(ignore: string[] = []) {
    const ret = []

    for (const k in this.new_values) {
      if (!ignore.includes(k)) {
        ret.push(k)
      }
    }

    return ret
  }

  public static toObject(data: any, cache: boolean = true): Audit {
    const audit = new Audit()

    audit.id = data.id
    audit.user_id = data.user_id
    audit.event = data.event
    audit.old_values = data.old_values
    audit.new_values = data.new_values
    audit.created_at = data.created_at
    audit.ip_address = data.ip_address
    audit.user_agent = data.user_agent
    audit.auditable_type = data.auditable_type
    audit.auditable_id = data.auditable_id

    if (data.user) {
      audit.user = User.toObject(data.user)
    }

    return audit
  }

  public static toObjectList(data: any, cache: boolean = true): Audit[] {
    const audits = new Array<Audit>()
    data.forEach((value: any) => {
      const audit = Audit.toObject(value, false)
      audits.push(audit)
    })

    return audits
  }

  /// State Management
  public static get module(): AuditModule {
    if (!store.hasModule('audit')) {
      store.registerModule('audit', AuditModule)
    }

    return getModule(AuditModule)
  }

  public static async paginate(options: PaginateOptions) {
    return Audit.module.paginate(options)
  }
}
