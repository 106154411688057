import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import Api from '@/models/Api'
import Audit from '@/models/Audit'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'audit',
})
export default class AuditModule extends VuexModule {
    public data: Audit[] = [];

    public synchronized: boolean = false;

    public get api() {
      return new Api(false)
    }

    @Mutation
    public setSynchronized(status: boolean) {
      this.synchronized = status
    }

    @Mutation
    public update(audits: Audit | Audit[]) {
      let data: Array<Audit> = []
      if (audits instanceof Audit) {
        data.push(audits)
      } else {
        data = audits
      }

      let result = this.data

      data.forEach((audit: Audit) => {
        let found: boolean = false
        result = result.map((obj: Audit) => {
          if (obj.id == audit.id) {
            found = true
            return audit
          }
          return obj
        })

        if (!found) {
          result.push(audit)
        }
      })

      this.data = result
    }

    @Mutation
    public delete(audits: Audit | Audit[]) {
      let data: Array<Audit> = []
      if (audits instanceof Audit) {
        data.push(audits)
      } else {
        data = audits
      }

      let result = this.data

      data.forEach((audit: Audit) => {
        result = result.filter((obj: Audit) => obj.id != audit.id)
      })

      this.data = result
    }

    @Action
    public async find(id: string) {
      return this.api.get(`audit/${id}`)
        .then(response => {
          const o = Audit.toObject(response.data.result.audit)

          this.context.commit('update', o)

          if (o instanceof Audit) {
            return o
          }

          return null
        }).catch(() => null)
    }

    @Action
    public async paginate(options: PaginateOptions) {
      return this.api.get('audits/paginate', options)
        .then(response => {
          const o = Audit.toObjectList(response.data.result.audits)

          return {
            records: response.data.result.records,
            data: o,
          }
        }).catch(() => ({
          records: 0,
          data: [],
        }))
    }
}
